/* Framework Begin  */
.inputLogin {
    margin-bottom: 5px;
}

#ffwd_container1_0 #ffwd_container2_0 .ffwd_blog_style_object_info_container_0 .ffwd_blog_style_object_from_name_0 {
    color: #ffffff !important;
}

#ffwd_container1_0 #ffwd_container2_0 .ffwd_blog_style_object_info_container_0 .ffwd_from_time_post_0 {
    color: #ffffff !important;
}

#ffwd_container1_0 #ffwd_container2_0 .ffwd_blog_style_object_messages_0 {
    color: #ffffff !important;
}

#ffwd_container1_0 #ffwd_container2_0 .ffwd_blog_style_object_info_container_0 .ffwd_see_more_message {
    color: #ffffff !important;
}

.modal-content {
    background-color: #272b30;
}

.traileriframe {
    width:100%;
    height:100%;
    min-height:300px;
    overflow:hidden;
    border:none;
}

.btn-trailer {
    width: 100%;
    margin-top: 15px;
}

.modal-header {
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    filter: none;
    color: white;
}

.modal-footer {
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    filter: none;
    color: white;
}

.contentContainer {
    padding-bottom: 60px;
}

.noPadding {
    padding: 0;
}

.noPaddingRight {
    padding-right: 0;
}

a, a:hover, a:active {
    color: #fff !important;
    text-decoration: none !important;
}

    a:hover {
        color: #fff !important;
        text-decoration: underline;
    }

    a:active {
        color: #fff !important;
        text-decoration: underline;
    }

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.card-header {
    color: #c8c8c8;
    background-color: #3e444c;
    border-color: rgba(0, 0, 0, 0.6);
    border-radius: 0;
    font-size: 14px;
}

.speedList {
    margin-top: 20px;
    font-size: 11px;
    color: #c8c8c8;
    background-color: #32383e;
}

.list-group-item {
    padding: 5px;
    color: #c8c8c8;
    background-color: #3e444c;
    border: solid 1px #000;
    margin-bottom: 3px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

/* Footer begin */

.footerCopyright {
    float:right;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 40px;
    z-index: 999;
}
.footer {
    background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    -webkit-filter: none;
    filter: none;
    border: 1px solid rgba(0, 0, 0, 1);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}

.footer > .container .text-muted {
    margin: 8px 0;
}
/* Footer end */
.leftDiv {
    float: left;
    width: 70%;
}

.rightDiv {
    float: right;
    width: 30%;
    height: 249px;
}

.text-muted {
    color: #7a8288;
}

.jumbotronbtn {
    margin-right: 5px;
}

.input-group-text {
    color: #c8c8c8;
    background-color: #3e444c;
    border: #000 solid 1px;
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    font-size: 12px;
}

.dropdown-toggle {
    color: #c8c8c8;
    background-color: #3e444c;
    border: #000 solid 1px;
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    font-size: 12px;
}

.card-body {
    color: #c8c8c8;
    background-color: #32383e;
    border-color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    max-height: 248px;
}

.quickListBody {
    color: #c8c8c8;
    background-color: #32383e;
    border-color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    max-height: 178px;
    overflow: auto;
}

.card {
    border-color: #000;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    background-color: #2e3338;
    border: 1px solid transparent;
    border-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

body {
    background-color: #272b30 !important;
    color: #c8c8c8 !important;
}

html {
    font-size: 14px;
    color: #c8c8c8;
}

p, h1, h2, h3 {
    color: #c8c8c8;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}
/* Framework End  */

/* Film Begin */
.filmShowingHeaderCell {
    text-align: center;
}

.filmShowingCell {
    text-align: center;
}
/* Film End */

/* Medlem Begin */
.tabActive {
    background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    -webkit-filter: none;
    filter: none;
    color: white;
    -moz-border-radius-topleft: 15px;
    -moz-border-radius-topright: 15px;
    text-shadow: cornsilk;
    cursor: pointer;
    border: 1px solid white !important;
}

.tabInactive {
    border: #c8c8c8 1px solid;
    color: #ffffff;
    cursor: pointer;
}

.tabContent {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #2e3338;
    border: #c8c8c8 1px solid;
}

.inputMemberProfileEmail {
    margin-top:10px;
    float:left;
}

.btnSaveMemberProfileEmail {
    margin-top:10px;
    margin-left:10px;
    float:left;
}

/* Medlem End */

/* Boka Begin */
.imgChair{
    width:12px;
    margin-left: 8px;
    margin-right: 4px;
}

.divBokaEmail {
    vertical-align: center;
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
}

.txtBokaEmail {
    font-size: 0.8rem !important;
    height: calc(1.2em + 0.75rem + 2px)  !important;
}

.btnBokaConfirm {
    background-image: linear-gradient(#26c137, #55f358 60%, #55f358) !important;
    color: black !important;
    font-weight: bold;
    float: right;
    margin-top: 15px;
}

.btnCookieConfirm {
    background-image: linear-gradient(#26c137, #55f358 60%, #55f358) !important;
    color: black !important;
    z-index: 9999999999999;
}

.divCookieContainer {
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 30%);
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539) !important;
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    filter: none;
    color: white;
    text-shadow: cornsilk;
}

.btnBokaUpdateNumber {
    float: right;
    margin-right: 15px;
    margin-top: 15px;
}

.divBokaSalongLayoutDescription {
    margin-top: 15px;
}

.divBokaHeader {
    background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    -webkit-filter: none;
    filter: none;
    color: white;
    text-shadow: cornsilk;
}

.divBokaSalonLayout {
    border: 1px solid #7a8288;
    background-color: #1c1e22;
    padding: 3px;
    border-radius: 3px;
    text-align: center;
    min-height: 300px;
}

.bokaCinemaScreen {
    color:white; 
    margin-bottom:20px;
    margin-top:0px;
    margin-left: 10%;
    margin-right: 10%;
}

hr {
    border: 1px solid #ffffff;
}

.divBookingDetails{
    border: 1px solid #7a8288;
    background-color: #2e3338;
    padding: 0px;
    border-radius: 3px;
}

.divMovieImage {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 0px;
    width: 100%;
    border: 4px solid #1c1e22;
}

.divShowInfo {
    font-size: 0.9rem;
    padding: 5px;
}

.divBokaImageCell {
    padding: 3px;
    align-content: center;
    text-align: center;
}

.pBookMovieDescription {
    /* overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;*/
}

.divBokaAirTime{
    float:left;
    margin-right: 15px;
    margin-top: 5px;
}

.rowBoka:nth-of-type(even) {
    background: #575F6B;
    background-color: #575F6B;
    text-shadow: #000000;
}

.tableBoka{
    background: #353a41;
    background-color: #3e444c;
    text-shadow: #000000;
}
/* Boka End */

/* Members Page Begin  */
.rowBokat {
    border-bottom: 8px solid #272b30;
    background-color: #3e444c;
    text-shadow: #000000;
    padding: 5px;
}
/* Members Page End  */

/* Home Page Begin  */
.divSuperFilm {
    height: 300px;
}
    
.jumbotronimage {
    background-image: url(https://www.eurostar.se/Images/Superfilm/closed.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent !important;
    height: 300px !important;
}

@media (min-width: 1400px) {
    .jumbotronimage {
        height: 350px !important;
    }
    .divSuperFilm {
        height: 350px;
    }
}

.bioProgramRad {
    font-size: 1.1rem;
}

.text-center {
    width: auto;
    position: relative;
    bottom: 40px;
    right: 10px;
    height: 30px;
    text-align: right !important;
    z-index: 2;
}

.modal {
    z-index: 999999;
}

.white {
    color: #ffffff;
}

.quickdatedropdown {
    background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    -webkit-filter: none;
    filter: none;
    color: white;
    text-shadow: cornsilk;
    border: 1px solid black !important;
}

.quickdatedropdownBtn {
    background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    -webkit-filter: none;
    filter: none;
    color: white;
    text-shadow: cornsilk;
    border: 1px solid black !important;
    width: 130px;
}

.speedListAirTime {
    float:right;
}

.divCarousel {
    background-color: #3e444c;
    padding: 5px;
    border: solid 1px black;
    border-radius: 5px;
}

.btn {
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    border-color: rgba(0, 0, 0, 0.6);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.movieCarouselDiv {
    width:95%;
}

.alice-carousel__stage-item * {
    line-height: initial;
}

.movieCarouselImage {
    background-color: #000;
    width:100%;
    max-width: 235px;
    max-height: 319px;
    padding: 1px;
}

.btnBioprogram {
    background: none !important;
    border: none;
    padding: 0 !important;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}

.divSlider {
    background-color: #3e444c;
    padding: 5px;
    margin-top: 10px;
}

.spanMovieSliderInfo {
    width: 198px;
    background-color: #000;
    color: #ffffff;
    position: fixed;
    bottom: 0px;
    font-size: 12px;
    text-align: center;
    height: 23px;
    z-index: 99;
    padding-top: 3px;
}

.swiper-free-mode > .swiper-wrapper {
    transition-timing-function: linear !important;
}
.swiper-slide {
    width: 28vw;
    overflow: hidden;
    background-position: top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
}
.swiper-slide {
    width: 28vw;
    overflow: hidden;
    background-position: top;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 20px;
    font-weight: bold;
}

.swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
}

.swiper-slide {
    overflow: hidden;
    background-position: center center;
    background-size: cover;
}

.alert {
    margin-top: 20px;
    margin-bottom: 20px;
}

.divMobileSuperFilm {
    display: none;
}

@media screen and (max-width: 900px) {
    .divSlider {
        margin-top: 15px;
        margin-bottom: 20px;
    }

    .divQuickList {
        padding-left:0px;
        margin-bottom: 15px;
    }    
}

.alice-carousel__dots, alice-carousel__dots-item {
    display: none !important;
}

.swiper-wrapper {
    transition-timing-function: linear;
}

@media only screen and (max-width: 500px) {
    .divMobileSuperFilm {
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: transparent !important;
        border: none !important;
        margin-bottom: 25px;
        min-height: 350px;
    }

    .movieCarouselImage {
        max-width: 366px;
        max-height: 550px;
    }

    .spanMovieSliderInfo {
        width: 361px;
    }

    .hidden-xs {
        display: none;
    }

    .divBokaSalonLayout {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .jumbotronimage {
        width: 100% !important;
        height: 550px !important;
    }

    .divSuperFilm {
        display: none;
    }

    .text-center {
        bottom: 45px;
        right: 5px;
    }

    .footer {
        height: 60px;
    }
}
/* Home Page End  */