.nav-item {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}
    
.page-header {
    margin-top: 40px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
    color: white !important;
}

.dropdown-toggle {
    border: none !important; 
    background-image: none !important;
    background-repeat: no-repeat;
    background-color: transparent !important;
    color: white;
    text-shadow: cornsilk;
    font-size: 1rem !important;
}

.dropdown-item {
    color: #fff;
    font-size: 12px !important;
}

.dropdown-menu, .droppetydown {
    background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    -webkit-filter: none;
    filter: none;
    color: white;
    text-shadow: cornsilk;
    border-radius: 0 !important;
}

.quickDateDropDown {
    font-size: 12px !important;
}

.navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
}

.nav-link {
    color: white !important;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/* General Navbar styling */
.navBarDark {
    background-image: -webkit-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -o-linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-image: -webkit-gradient(linear, left top, left bottom, from(#484e55), color-stop(60%, #3a3f44), to(#313539));
    background-image: linear-gradient(#484e55, #3a3f44 60%, #313539);
    background-repeat: no-repeat;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff484e55', endColorstr='#ff313539', GradientType=0);
    -webkit-filter: none;
    filter: none;
    color: white;
    text-shadow: cornsilk;
}

.navBarDark {
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
/* Navbar buttons styling */
.navBarDark .navbar-nav > li > a:hover {
    background: none;
    filter: none;
    color: white !important;
}

.navbar-nav {
    float: left !important;
}